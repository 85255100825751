import React, { useEffect, useState, useRef } from "react";
import epf from "../../../images/finance-service-epf.jpg";
import digitalGold from "../../../images/finance-service-gold.jpg";
import directMfIllustration from "../../../images/finance-service-mf.jpg";
import style from "../landing.module.css";
import { Spring, config } from "react-spring/renderprops";
import { useTransition, animated } from "react-spring";
const TIMER = 5000;

const SOLUTIONS = [
  {
    title: "Direct Mutual Funds",
    subtitle:
      "By providing only direct plans, we don’t let commissions eat away your returns. Invest in best performing mutual funds hand picked by experts through our proprietary algorithms.",
    illustration: directMfIllustration,
    ctaText: "Start Investing Now",
    link: "https://www.piggy.co.in/search/"
  },
  {
    title: "Digital Gold",
    subtitle:
      "Buy gold of highest purity (24K) directly from refiner (Augmont) at lowest possible prices in India. Gold is stored in 100% insured & secured government vault.",
    illustration: digitalGold,
    ctaText: "Buy Digital Gold",
    link: "https://www.piggy.co.in/gold/"
  },
  {
    title: "EPF Tracker",
    subtitle:
      "Add your Employee Provident Fund Account to get details on your current EPF balance & Service History. Get a better look at detailed PF performance with projected Retirement corpus.",
    illustration: epf,
    ctaText: "Link EPF Account",
    link: "https://www.piggy.co.in/epf/"
  }
];

const FinancialSolutions = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isMobile, setIsMobile] = useState(TIMER);
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 968) setIsMobile(true);
    else setIsMobile(false);
  }, []);

  const transitions = useTransition(activeTab, p => p, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
    // config: { duration: 200 }
  });

  return (
    <div className={style.financialSolutionContainer} ref={domRef}>
      {isMobile && (
        <p className={`${style.financialSolutionsTitle} heading-bold`}>
          Our Financial Solutions
        </p>
      )}
      <div className={style.responsiveFlex}>
        <div style={{ margin: "auto 0" }}>
          {!isMobile && (
            <p className={`${style.financialSolutionsTitle} heading-bold`}>
              Our Financial Solutions
            </p>
          )}
          <div className={style.financialSolutionButtonContainer}>
            {SOLUTIONS.map((sol, i) => (
              <button
                className={
                  i === activeTab ? style.activeTab : style.inactiveTab
                }
                onClick={() => setActiveTab(i)}
              >
                {sol.title}
              </button>
            ))}
          </div>

          <Spring from={{ opacity: 0 }} to={{ opacity: isVisible ? 1 : 0 }}>
            {props => (
              <p
                className={`para-20px text-grey-2 ${style.financialSolutionsSubtitle}`}
                style={props}
              >
                {SOLUTIONS[activeTab].subtitle}
              </p>
            )}
          </Spring>

          <a href={SOLUTIONS[activeTab].link}>
            <button className={style.financialSolutionCta}>
              <p>{SOLUTIONS[activeTab].ctaText}</p>
            </button>
          </a>
        </div>

        {transitions.map(({ item, props, key }) => (
          <animated.img
            key={key}
            style={props}
            src={SOLUTIONS[item].illustration}
            alt={SOLUTIONS[item].title}
            className={style.financialSolutionIllustration}
          />
        ))}
      </div>
    </div>
  );
};

export default FinancialSolutions;
