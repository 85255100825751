import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal/index";
const TIMER = 10000;

function RegulatoryDisclosureModal() {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date();
  var month = date.getMonth() - 1;
  var year = date.getFullYear();
  if (month == -1) {
    month = 11;
    year = year - 1;
  }

  const counterValue = TIMER / 1000;
  const [show, setShow] = useState(false);
  const [counter, setCounter] = useState(counterValue);

  useEffect(() => {
    if (counter == counterValue) {
      setShow(true);
    }
    const interval = setInterval(() => {
      if (counter == 1) {
        setShow(false);
        clearInterval(interval);
      }
      setCounter(counter - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [counter]);

  const styleRegulatoryContainer = { margin: "24px", "max-width": "600px" };
  const styleRegulatoryHeading = {
    "text-align": "center",
    "font-size": "22px",
    "font-weight": "bold",
  };
  const styleRegulatorySubHeading = {
    "margin-top": "24px",
    "text-align": "center",
    "font-size": "16px",
  };
  const styleRegulatoryTable = {
    width: "70%",
    "margin-left": "auto",
    "margin-right": "auto",
    "margin-top": "16px",
    "border-color": "black",
    border: "1px solid #ddd",
    "border-collapse": "collapse",
    "text-align": "center",
    color: "#717375",
    padding: "15px",
  };
  const styleRegulatoryHeader = { "font-weight": "bold", color: "black" };
  const styleCounter = {
    "text-align": "center",
    "font-size": "14px",
    color: "#c5c5c5",
  };
  const styleButton = {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    margin: "16px",
  };

  return (
    <Modal show={show}>
      <div style={styleRegulatoryContainer}>
        <p style={styleRegulatoryHeading}>Regulatory Disclosure</p>
        <p style={styleRegulatorySubHeading}>
          Valuevest Services Private Limited (tradename: Piggy) is a SEBI
          Registered Investment Advisor (INA000011343). SEBI requires RIA's to
          disclose complaint status on their homepage. Pursuant to the same,
          please note:
        </p>
        <table style={styleRegulatoryTable}>
          <tr style={styleRegulatoryHeader}>
            <th>
              Complaint Status as on {months[month]}-{year}
            </th>
            <th>Number of complaints</th>
          </tr>
          <tr>
            <td>At the beginning of month</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Received during the month</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Resolved during the month</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Pending at the ending of the month</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Reason for pendency</td>
            <td>NA</td>
          </tr>
        </table>
        <div style={styleButton}>
          <button
            onClick={() => {
              setShow(false);
            }}
            className={"btn-primary-filled"}
          >
            Close
          </button>
        </div>
        <p style={styleCounter}>
          This window will auto close in {counter} seconds
        </p>
      </div>
    </Modal>
  );
}

export default RegulatoryDisclosureModal;
