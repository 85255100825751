import React, { useState, useEffect, useRef } from "react";
import style from "../landing.module.css";
import { Spring } from "react-spring/renderprops";
import growMoney from "../../../images/landing-page-grow-money.png";
import reduceTax from "../../../images/landing-page-reduce-tax.png";
import saveMore from "../../../images/landing-page-save-more.png";
import oneApp from "../../../images/landing-page-one-app.png";

const TIMER = 5000;

const HOW_WE_GROW_MONEY = [
  {
    menuText: "Grow Money",
    title: "Grow money up to 45% more",
    subtitle:
      "You invest in commission free mutual funds which helps you grow your money up to 45% more in 25 years.",
    hyperlinkText: "Learn More",
    buttonText: "Grow My Money",
    illustration: growMoney,
    hyperLink:
      "https://www.piggy.co.in/blog/regular-vs-direct-mutual-fund-schemes/",
    link: "https://www.piggy.co.in/search/"
  },
  {
    menuText: "Reduce Tax",
    title: "Reduce Taxes up to ₹46,800",
    subtitle:
      "With the shortest Lock-in & better returns, ELSS empowers your wealth while you reduce your taxes.",
    hyperlinkText: "Learn More",
    buttonText: "Reduce Tax",
    illustration: reduceTax,
    hyperLink: "https://www.piggy.co.in/blog/tag/tax-saving-tips/",
    link: "https://www.piggy.co.in/tax-saving/"
  },
  {
    menuText: "Save more, Faster",
    title: "Save more money Effortlessly ",
    subtitle:
      "Save more with Simply Save and reach your short-term goals fasterSave more money faster and effortlessly",
    hyperlinkText: "Learn More",
    buttonText: "Save More",
    illustration: saveMore,
    hyperLink: "https://www.piggy.co.in/blog/simply-save-feature/",
    link: "https://www.piggy.co.in/simply-save/"
  },
  {
    menuText: "1 app for everything",
    title: "1 app for everything",
    subtitle:
      "Piggy brings all the investment options under 1 login, be it investing in Direct MFs, Digital Gold, Managing Portfolio or tracking EPF",
    buttonText: "Get Started",
    illustration: oneApp,
    link: "https://www.piggy.co.in/login/"
  }
];

const GrowMoney = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [duration, setDuration] = useState(TIMER);

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     let updatedValue = 0;
  //     if (duration <= 1) {
  //       setActiveTab((activeTab + 1) % 4);
  //       clearInterval(interval);
  //       updatedValue = TIMER;
  //     } else {
  //       updatedValue = Number(duration - 1000);
  //     }
  //     setDuration(updatedValue);
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, [duration]);

  const [isVisible, setVisible] = useState(true);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <div className={style.growMoneyContainer}>
      <div className={style.growmoneymenusection}>
        <div>
          {HOW_WE_GROW_MONEY.map((menu, i) => (
            <div
              // style={{ position: "relative" }}
              onClick={() => setActiveTab(i)}
            >
              <div>
                <p
                  className={style.growMoneyMenu}
                  style={{
                    color: i === activeTab ? "#fff" : "",
                    cursor: "pointer"
                  }}
                >
                  {menu.menuText}
                </p>
              </div>
              {i === activeTab && <div className={style.activeMenuIndicator} />}
            </div>
          ))}
        </div>
        <div className={style.vr} />
      </div>

      <div className={style.growMoneyDotsContainer}>
        {[1, 2, 3, 4].map((dot, i) => (
          <div
            className={
              i === activeTab
                ? style.growMoneyActiveDot
                : style.growMoneyInactiveDot
            }
            onClick={() => setActiveTab(i)}
            key={i}
          />
        ))}
      </div>
      <div className={style.growMoneyContentSection} ref={domRef}>
        <div className={style.growMoneyContentWrapper}>
          <Spring
            from={{ opacity: 0, marginTop: 10 }}
            to={{ opacity: isVisible ? 1 : 0, marginBottom: 0 }}
            config={{ duration: 1000 }}
          >
            {props => (
              <p
                className={`heading-40px text-white ${style.growMoneyTitle}`}
                style={props}
              >
                {HOW_WE_GROW_MONEY[activeTab].title}
              </p>
            )}
          </Spring>

          <Spring
            from={{ opacity: 0, marginTop: 10 }}
            to={{ opacity: isVisible ? 1 : 0, marginBottom: 0 }}
            config={{ duration: 1000 }}
          >
            {props => (
              <div style={props}>
                <p className={style.growMoneySubtitle}>
                  {HOW_WE_GROW_MONEY[activeTab].subtitle}&ensp;
                  <a
                    href={HOW_WE_GROW_MONEY[activeTab].hyperLink}
                    className="t-a-none"
                  >
                    <span className={style.growMoneyHyperlink}>
                      {HOW_WE_GROW_MONEY[activeTab].hyperlinkText}
                    </span>
                  </a>
                </p>
              </div>
            )}
          </Spring>

          <a
            href={HOW_WE_GROW_MONEY[activeTab].link}
            style={{ textDecoration: "none" }}
          >
            <button className={style.growMoneyButton}>
              {HOW_WE_GROW_MONEY[activeTab].buttonText}
            </button>
          </a>
        </div>

        <img
          src={HOW_WE_GROW_MONEY[activeTab].illustration}
          className={style.growMoneyIllustration}
        />
      </div>
    </div>
  );
};

export default GrowMoney;
