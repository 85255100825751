import React from "react";
import dotted from "../../../images/dotted.svg";
import style from "../landing.module.css";
import { Spring, config } from "react-spring/renderprops";
import { getRoundedInrFormat } from "../../../utilities";

const Vision = props => {
  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <div className={style.visionContainer} ref={domRef}>
      <div className={style.visionContnentWrapper}>
        <p className={`para-1 text-primary bold ${style.visionText}`}>
          Our Vision
        </p>
        <Spring
          from={{ opacity: 0, marginBottom: 100 }}
          to={{ opacity: isVisible ? 1 : 0, marginBottom: 0 }}
          config={config.molasses}
        >
          {props => (
            <p className={`heading-40px ${style.visionTitle}`} style={props}>
              Join the{" "}
              <span className="text-primary hind-madurai">5 lakh+</span> users
              community
            </p>
          )}
        </Spring>
        <Spring
          from={{ opacity: 0, marginBottom: 100 }}
          to={{ opacity: isVisible ? 1 : 0, marginBottom: 0 }}
          config={config.molasses}
        >
          {props => (
            <div style={props}>
              {" "}
              <p className={`para-1-lite ${style.visionSubtitle}`}>
                We at Piggy are reinventing the way you do and manage your
                investments. We are cutting out all the noise and complexity so
                that managing your money is no more rocket science.
              </p>
            </div>
          )}
        </Spring>
      </div>
      <div className={style.visionStatsContainer}>
        <div className={style.statsContainer}>
          <div className={style.statsBox}>
            <div className={style.stats}>
              <Spring from={{ number: 0 }} to={{ number: 1200 }} delay={1000}>
                {props => (
                  <p className={`heading-40px ${style.visionStatsText}`}>
                    ₹ {getRoundedInrFormat(props.number)} +
                  </p>
                )}
              </Spring>

              <p className={`para-20px text-grey-2 ${style.heroSubtitle}`}>
                Crore AUM
              </p>
            </div>
            <div className={style.stats}>
              <Spring from={{ number: 0 }} to={{ number: 35 }} delay={1000}>
                {props => (
                  <p className={`heading-40px ${style.visionStatsText}`}>
                    {getRoundedInrFormat(props.number)} +
                  </p>
                )}
              </Spring>
              <p className={`para-20px text-grey-2 ${style.heroSubtitle}`}>
                AMCs
              </p>
            </div>
            {/* <div className={style.stats}>
              <Spring from={{ number: 0 }} to={{ number: 800 }} delay={1000}>
                {props => (
                  <p className={`heading-40px ${style.visionStatsText}`}>
                    ₹ {getRoundedInrFormat(props.number)}
                  </p>
                )}
              </Spring>
              <p className={`para-20px text-grey-2 ${style.heroSubtitle}`}>
                Cities
              </p>
            </div> */}
            <div className={style.stats}>
              <Spring
                from={{ number: 0 }}
                to={{ number: 5000000 }}
                delay={1000}
              >
                {props => (
                  <p className={`heading-40px ${style.visionStatsText}`}>
                    {getRoundedInrFormat(props.number)}
                  </p>
                )}
              </Spring>

              <p className={`para-20px text-grey-2 ${style.heroSubtitle}`}>
                Transactions
              </p>
            </div>
            <div className={style.stats}>
              <Spring from={{ number: 0 }} to={{ number: 5 }} delay={1000}>
                {props => (
                  <p className={`heading-40px ${style.visionStatsText}`}>
                    ₹ {getRoundedInrFormat(props.number)} Cr +
                  </p>
                )}
              </Spring>
              <p className={`para-20px text-grey-2 ${style.heroSubtitle}`}>
                Commisions Saved
              </p>
            </div>
          </div>

          <div className={style.borderBottom} />
        </div>
        <img src={dotted} className={style.dottedImage} />
      </div>
    </div>
  );
};
export default Vision;
