import React from "react";
import trackFinanceIllustration from "../../../images/track-finance.svg";
import style from "../landing.module.css";
import { Spring, config } from "react-spring/renderprops";

const InvestmentsOnTrack = () => {
  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <div className={style.trackInvestmentContainer} ref={domRef}>
      <div className={style.trackInvestmentContentBox}>
        <div>
          <Spring
            from={{ opacity: 0, marginBottom: 1000 }}
            to={{ opacity: isVisible ? 1 : 0, marginBottom: 0 }}
            config={config.molasses}
          >
            {props => (
              <p
                className={`heading-bold ${style.trackInvestmentTitle}`}
                style={props}
              >
                Find out if your investments are{" "}
                <span className="text-primary hind-madurai"> on track</span>
              </p>
            )}
          </Spring>

          <Spring
            from={{ opacity: 0, marginBottom: 1000 }}
            to={{ opacity: isVisible ? 1 : 0, marginBottom: 0 }}
            config={config.molasses}
          >
            {props => (
              <p
                className={`para-1-lite text-grey-1 ${style.financialTrackSubtitle}`}
                style={props}
              >
                Link your financial accounts, we’ll give you insights into your
                portfolio such as sector exposure, return potential, fund
                Segmentation.
              </p>
            )}
          </Spring>
          <a href="https://www.piggy.co.in/login/">
            <button className={style.financialTrackCta}>Let's do it</button>
          </a>
        </div>

        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: isVisible ? 1 : 0 }}
          config={config.molasses}
        >
          {props => (
            <img
              src={trackFinanceIllustration}
              alt="track-finance"
              style={props}
            />
          )}
        </Spring>
      </div>
      <div className={style.financialTrackerHr} />
    </div>
  );
};

export default InvestmentsOnTrack;
