import React, { useState } from "react";
import aman from "../../../images/aman-review.png";
import harshal from "../../../images/harshal-review.png";
import prakhar from "../../../images/prakhar-review.png";
import invertedComma from "../../../images/inverted-comma.svg";
import style from "../landing.module.css";
import seeAll from "../../../images/see-all.svg";


const REVIEWS = [
  {
    review:
      "I started a year back with Piggy and have truly been happy with such an amazing service. The Support team has always gone out of the way to help me with my doubts. The UI in the app is flawless and they truly have made Investments simpler.",
    name: "Aman",
    // designation: "Designation",
    photo: aman
  },
  {
    review:
      "Was able to switch my Regular investments to Direct with just one click. Never saw an app with such easy flow. Piggy is the best investments app out there.",
    name: "Harshal Patel",
    // designation: "Designation",
    photo: harshal
  },
  {
    review:
      "User friendly interface. Great support team. Investing in mutual funds and keeping track of all your mutual funds became very easy. Thank you Piggy for the support.",
    name: "Prakhar Saxena",
    // designation: "Designation",
    photo: prakhar
  }
];

const CustomerReviews = () => {
  const [activeTab, setActiveTab] = useState(0);

  function handleActiveTab(direction) {
    switch (direction) {
      case "right":
        setActiveTab((activeTab + 1) % 3);
        break;
      case "left":
        if (activeTab === 0) {
          setActiveTab(2);
        } else {
          setActiveTab(activeTab - 1);
        }
    }
  }

  return (
    <div className={style.customerReviewsContainer}>
      <div className={style.reviewsContainer}>
        <div
          className={`${style.nextIconContainer} ${style.nextIconContainerLeft}`}
          onClick={() => handleActiveTab("left")}
        >
          <img src={seeAll} alt="left" />
        </div>
        <img
          src={invertedComma}
          alt="quote"
          style={{ display: "block", margin: "auto" }}
        />
        <p className={style.reviewText}> {REVIEWS[activeTab].review}</p>
        <div className={style.customerDetailsContainer}>
          <img
            src={REVIEWS[activeTab].photo}
            alt={REVIEWS[activeTab].name}
            className={style.customerPhoto}
          />
          <div style={{ margin: "auto 12px" }}>
            <p className={style.customerName}>{REVIEWS[activeTab].name}</p>
            <p className={style.customerDesignation}>
              {REVIEWS[activeTab].designation}
            </p>
          </div>
        </div>
        <div
          className={`${style.nextIconContainer} ${style.nextIconContainerRight}`}
          onClick={() => handleActiveTab("right")}
        >
          <img src={seeAll} alt="right" />
        </div>
      </div>
    </div>
  );
};

export default CustomerReviews;
