import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/SEO/SEO";
import style from "./landing.module.css";
import Vision from "./components/Vision";
import HeroSection from "./components/HeroSection";
import GrowMoney from "./components/GrowMoney";
import FinancialSolutions from "./components/FinancialSolutions";
import CustomerReviews from "./components/CustomerReviews";
import InvestmentsOnTrack from "./components/InvestmentsOnTrack";
import DownloadApp from "./components/DownloadApp";
import RegulatoryDisclosureModal from "./components/RegulatoryDisclosureModal";

const LandingPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  useState(() => {
    if (typeof window !== "undefined") {
      if (window.innerWidth <= 640) {
        setIsMobile(true);
      }
    }
  }, []);
  return (
    <Layout>
      <SEO link="/" />
      <div className={style.mainContainer}>
        <HeroSection isMobile={isMobile} />
        <Vision />
        <GrowMoney />
        <FinancialSolutions />
        <CustomerReviews />
        <InvestmentsOnTrack />
        <DownloadApp />
        {!isMobile && <RegulatoryDisclosureModal isMobile={isMobile} />}
      </div>
    </Layout>
  );
};

export default LandingPage;
