import React, { useState } from "react";
import style from "../landing.module.css";
import heroIllustration from "../../../images/hero-illustration.png";
import featuredIn from "../../../images/featured-in-image.png";
import heroVideo from "../../../video/landing-page-intro.mp4";
import playButton from "../../../images/play-button.svg";
import { Spring, config } from "react-spring/renderprops";
import VideoModal from "../../Premier/components/VideoModal";

const HeroSection = ({ isMobile }) => {
  const [showModal, setModalVisibility] = useState(false);
  return (
    <div className={style.heroContainer}>
      {showModal && (
        <VideoModal
          link="https://www.youtube.com/embed/3NFxuiJCaN0/?autoplay=1"
          show={showModal}
          close={() => setModalVisibility(false)}
          isMobile={isMobile}
        />
      )}
      <div className={style.heroContentWrapper}>
        <div className={style.heroTitleContainer}>
          <Spring
            from={{ opacity: 0, marginTop: 10 }}
            to={{ opacity: 1, marginBottom: 0 }}
            config={{ duration: 1000 }}
          >
            {props => (
              <p className={`heading-bold ${style.heroTitle}`} style={props}>
                Simplifying{" "}
                <span className="text-primary hind-madurai">Investments</span>
              </p>
            )}
          </Spring>
        </div>
        <Spring
          from={{ opacity: 0, marginTop: 10 }}
          to={{ opacity: 1, marginBottom: 0 }}
          config={{ duration: 1000 }}
        >
          {props => (
            <>
              <p
                className={`para-20px text-grey-2 ${style.heroSubtitle}`}
                style={props}
              >
                India’s first direct mutual fund investment platform.
              </p>
              <p
                className={`para-20px text-grey-2 ${style.heroSubtitle}`}
                style={props}
              >
                Zero Fees, Higher Returns. It's that simple.
              </p>
            </>
          )}
        </Spring>
        <div className={`d-f ${style.mt48}`}>
          <a href={"https://www.piggy.co.in/signup/"}>
            <button className={`btn-primary-filled ${style.heroCta}`}>
              Get Started
            </button>
          </a>

          <div className="d-f c-p" onClick={() => setModalVisibility(true)}>
            <img src={playButton} className={style.heroSeePromoButton} />
            <p
              className="para-1-bold text-violet-dark align-self"
              style={{ marginLeft: 16 }}
            >
              Watch Video
            </p>
          </div>
        </div>
        <img
          src={featuredIn}
          alt="featuredin"
          className={style.featuredContainer}
        />
      </div>
      <video
        className={style.heroIllustration}
        autoPlay
        loop
        preload="auto"
        muted
      >
        <source src={heroVideo} type="video/mp4" />
        <img src={heroIllustration} />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default HeroSection;
