import React from "react";
import appStore from "../../../images/app-store-dark.svg";
import playStore from "../../../images/play-store-dark.svg";
import style from "../landing.module.css";

const DownloadApp = () => (
  <div className={style.downloadAppContainer}>
    <p className={`heading-bold ${style.downloadAppTitle}`}>
      Join the 5 lakh+ strong community
    </p>
    <p className={style.downloadAppSubtitle}>
      Download our app in less than a minute and join 500,000+ people on their
      way to reach their financial goals
    </p>
    <div className={style.downloadAppIconContainer}>
      <a href="https://itunes.apple.com/us/app/piggy-all-mutual-funds-in-one/id1167413844?mt=8&utm_source=website%26utm_medium%3Dwebsite%26utm_content%3Dweb_closed_page" target="_blank" >
        <img src={appStore} alt="appstore" className={style.downloadAppIcon} />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.valuevest&referrer=utm_source%3Dwebsite%26utm_medium%3Dwebsite%26utm_term%3Ddownload_app%26utm_content%3Dwebsite_footer" target="_blank">
        <img
          src={playStore}
          alt="playstore"
          className={style.downloadAppIcon}
        />
      </a>
    </div>
  </div>
);

export default DownloadApp;
